.central-loader {
  visibility: visible;
  -webkit-transition: visibility 0.3s;
  transition: visibility 0.3s;
  position: absolute;
}

.central-loader .loader-progress {
  position: fixed;
  background-color: #1890ff;
  z-index: 2010;
  height: 3px;
  width: 0;
}

.central-loader .ant-spin {
  position: fixed;
  z-index: 2010;
  right: 20px;
  top: 19px;
}

.central-loader.animate {
  visibility: visible;
}

.central-loader.loaded {
  visibility: hidden;
}

.central-loader.animate .ant-spin {
  visibility: visible;
}

.central-loader.loaded .ant-spin {
  visibility: hidden;
}

.central-loader.loaded .loader-progress {
  width: 100%;
}

.central-loader.animate .loader-progress {
  animation: myanimate 3s;
}

@keyframes myanimate {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.site-layout-background {
  background: #fff;
}

.site-page-header {
  border: 1px solid rgb(235, 237, 240);
  background-color: #fff;
}

.ant-table-column-sorter-up, .ant-table-column-sorter-down {
  font-size: 9px;
}

.ant-select-selection-search {
  min-width: 6px;
}

.ant-select-multiple .ant-select-selection-placeholder {
  left: 13px;
}